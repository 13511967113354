import React from "react";

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="141.732"
    height="141.732"
    version="1.1"
    viewBox="0 0 141.732 141.732"
    xmlSpace="preserve"
  >
    <path d="M95.35 50.645c0 13.98-11.389 25.322-25.438 25.322-14.051 0-25.438-11.342-25.438-25.322 0-13.984 11.389-25.322 25.438-25.322C83.964 25.322 95.35 36.66 95.35 50.645m26.393 0C121.743 22.674 98.966 0 70.866 0 42.768 0 19.989 22.674 19.989 50.645c0 12.298 4.408 23.574 11.733 32.345l39.188 56.283 39.761-57.104a50.048 50.048 0 003.916-5.625l.402-.574h-.066a50.245 50.245 0 006.82-25.325"></path>
  </svg>
);
