import React from "react";

export default (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
  >
    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
    <g>
      <path d="M500,990C229.8,990,10,770.2,10,500S229.8,10,500,10s490,219.8,490,490c0,161.8-79.8,313.1-213.5,404.6c-16,11-37.9,6.9-48.9-9.2c-11-16-6.9-37.9,9.2-48.9c114.5-78.4,183-207.9,183-346.6c0-231.4-188.3-419.6-419.6-419.6S80.4,268.6,80.4,500S268.6,919.6,500,919.6c19.4,0,35.2,15.7,35.2,35.2S519.4,990,500,990z M763.8,397.4H406.2c-19.4,0-35.2-15.7-35.2-35.2c0-19.4,15.7-35.2,35.2-35.2h357.6c19.4,0,35.2,15.7,35.2,35.2C799,381.7,783.2,397.4,763.8,397.4z M763.8,538.1H236.2c-19.4,0-35.2-15.7-35.2-35.2c0-19.4,15.7-35.2,35.2-35.2h527.6c19.4,0,35.2,15.7,35.2,35.2C799,522.4,783.2,538.1,763.8,538.1z M763.8,673H541c-19.4,0-35.2-15.7-35.2-35.2s15.7-35.2,35.2-35.2h222.8c19.4,0,35.2,15.7,35.2,35.2C799,657.2,783.2,673,763.8,673z" />
    </g>
  </svg>
);
