import React from "react";

export default (
  <svg
    width="102px"
    height="81px"
    viewBox="0 0 102 81"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path d="M30.511719,37.081 C28.163719,36.405 25.815719,36.062 23.531719,36.062 C20.004719,36.062 17.061719,36.868 14.779719,37.855 C16.979719,29.801 22.264719,15.904 32.792719,14.339 C33.767719,14.194 34.566719,13.489 34.832719,12.54 L37.133719,4.31 C37.327719,3.614 37.212719,2.869 36.815719,2.265 C36.418719,1.661 35.780719,1.258 35.065719,1.16 C34.288719,1.054 33.496719,1 32.711719,1 C20.074719,1 7.55971902,14.19 2.27871902,33.076 C-0.821280978,44.156 -1.73028098,60.814 5.90571902,71.299 C10.178719,77.166 16.412719,80.299 24.434719,80.612 C24.467719,80.613 24.499719,80.614 24.532719,80.614 C34.430719,80.614 43.207719,73.948 45.877719,64.405 C47.472719,58.7 46.751719,52.717 43.845719,47.554 C40.970719,42.449 36.235719,38.728 30.511719,37.081 Z" />
      <path d="M98.471,47.413 C95.596,42.307 90.861,38.586 85.137,36.939 C82.789,36.263 80.441,35.92 78.158,35.92 C74.631,35.92 71.687,36.726 69.405,37.713 C71.605,29.659 76.89,15.762 87.419,14.197 C88.394,14.052 89.192,13.347 89.459,12.398 L91.76,4.168 C91.954,3.472 91.839,2.727 91.442,2.123 C91.046,1.519 90.408,1.116 89.692,1.018 C88.916,0.912 88.124,0.858 87.338,0.858 C74.701,0.858 62.186,14.048 56.904,32.934 C53.805,44.014 52.896,60.672 60.533,71.159 C64.805,77.025 71.04,80.159 79.061,80.471 C79.094,80.472 79.126,80.473 79.16,80.473 C89.057,80.473 97.835,73.807 100.505,64.264 C102.098,58.559 101.376,52.575 98.471,47.413 Z" />
    </g>
  </svg>
);
