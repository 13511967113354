import React from "react";

export default (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 198.511 198.511"
  >
    <path
      d="M198.487,82.418c-0.436,15.134-6.859,29.652-18.087,40.879c-0.977,0.977-2.256,1.465-3.536,1.465
	c-1.28,0-2.559-0.488-3.536-1.465L75.213,25.182c-0.938-0.938-1.464-2.209-1.464-3.535s0.527-2.598,1.464-3.535
	C86.892,6.432,101.987,0,117.716,0c14.821,0,28.865,5.881,39.544,16.561l0.323,0.323c1.858,1.858,1.96,4.838,0.235,6.819
	l-17.876,20.524l14.341,14.341l20.525-17.876c1.981-1.726,4.961-1.624,6.819,0.235l0.323,0.323
	C193.059,52.358,198.932,66.979,198.487,82.418z M26.729,118.343L9.248,137.134l71.945,33.695l19.29-17.944L26.729,118.343z
	 M51.365,91.859l-17.481,18.792l74.494,34.89l19.29-17.945L51.365,91.859z M141.942,107.243L91.268,56.569
	c-0.96-0.96-2.261-1.517-3.626-1.464c-1.357,0.024-2.646,0.6-3.571,1.594L58.52,84.167l77.045,36.084l6.247-5.812
	c0.994-0.925,1.569-2.214,1.593-3.571C143.43,109.512,142.902,108.202,141.942,107.243z M2.093,144.826l-0.754,0.811
	c-1.833,1.97-1.777,5.038,0.125,6.94l44.469,44.469c0.975,0.975,2.254,1.465,3.536,1.465c1.221,0,2.444-0.445,3.405-1.339
	l20.422-18.998L2.093,144.826z"
    />
  </svg>
);
