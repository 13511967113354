import React from "react";
export default (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 480 480"
    style={{ enableBackground: "new 0 0 480 480" }}
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M80,48H16C7.168,48,0,55.168,0,64v64c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16V80h48c8.832,0,16-7.168,16-16
          C96,55.168,88.832,48,80,48z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M464,336c-8.832,0-16,7.168-16,16v48h-48c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h64c8.832,0,16-7.168,16-16v-64
          C480,343.168,472.832,336,464,336z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M464,48h-64c-8.832,0-16,7.168-16,16c0,8.832,7.168,16,16,16h48v48c0,8.832,7.168,16,16,16c8.832,0,16-7.168,16-16V64
          C480,55.168,472.832,48,464,48z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M80,400H32v-48c0-8.832-7.168-16-16-16c-8.832,0-16,7.168-16,16v64c0,8.832,7.168,16,16,16h64c8.832,0,16-7.168,16-16
          C96,407.168,88.832,400,80,400z"
        />
      </g>
    </g>
    <g>
      <g>
        <rect x={64} y={112} width={32} height={256} />
      </g>
    </g>
    <g>
      <g>
        <rect x={128} y={112} width={32} height={192} />
      </g>
    </g>
    <g>
      <g>
        <rect x={192} y={112} width={32} height={192} />
      </g>
    </g>
    <g>
      <g>
        <rect x={256} y={112} width={32} height={256} />
      </g>
    </g>
    <g>
      <g>
        <rect x={320} y={112} width={32} height={192} />
      </g>
    </g>
    <g>
      <g>
        <rect x={384} y={112} width={32} height={256} />
      </g>
    </g>
    <g>
      <g>
        <rect x={128} y={336} width={32} height={32} />
      </g>
    </g>
    <g>
      <g>
        <rect x={192} y={336} width={32} height={32} />
      </g>
    </g>
    <g>
      <g>
        <rect x={320} y={336} width={32} height={32} />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
