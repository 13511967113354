import React from "react";

export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="1708pt"
    version="1.1"
    viewBox="0 0 1708 1708.7495"
    width="1708pt"
  >
    <g id="surface1">
      <path d="M 224.941406 305.355469 C 230.28125 306.695312 235.167969 309.464844 239.0625 313.355469 L 674.136719 748.4375 L 752.863281 669.71875 L 317.78125 234.636719 C 313.878906 230.738281 311.121094 225.835938 309.773438 220.480469 L 282.390625 111.324219 L 100.867188 7.546875 L 11.976562 96.433594 L 115.785156 278.082031 Z M 224.941406 305.355469 " />
      <path d="M 611.824219 1297.789062 L 1302.21875 607.382812 C 1309.78125 599.8125 1320.753906 596.777344 1331.128906 599.359375 C 1422.027344 622.65625 1518.578125 602.46875 1592.558594 544.75 C 1666.550781 487.027344 1709.589844 398.261719 1709.09375 304.429688 C 1709.09375 298.714844 1708.925781 292.957031 1708.554688 287.148438 L 1547.976562 447.707031 C 1539.839844 455.851562 1527.773438 458.707031 1516.835938 455.058594 L 1334.199219 394.171875 C 1325.105469 391.160156 1317.972656 384.027344 1314.9375 374.941406 L 1254.066406 192.300781 C 1250.414062 181.378906 1253.261719 169.308594 1261.417969 161.15625 L 1421.992188 0.585938 C 1325.296875 -5.429688 1231.550781 35.195312 1169.789062 109.835938 C 1108.035156 184.492188 1085.71875 284.1875 1109.738281 378.035156 C 1112.339844 388.425781 1109.308594 399.394531 1101.738281 406.960938 L 411.355469 1097.320312 C 403.78125 1104.832031 392.820312 1107.878906 382.4375 1105.335938 C 358.402344 1099.164062 333.683594 1095.964844 308.855469 1095.867188 C 177.757812 1095.003906 60.621094 1177.648438 17.457031 1301.464844 C -25.683594 1425.269531 14.71875 1562.820312 117.96875 1643.625 C 221.21875 1724.429688 364.445312 1730.609375 474.257812 1658.96875 C 584.078125 1587.332031 636.148438 1453.769531 603.785156 1326.699219 C 601.210938 1316.328125 604.253906 1305.339844 611.824219 1297.789062 Z M 382.949219 1522.03125 L 234.785156 1522.03125 L 161.710938 1400.277344 L 234.785156 1278.503906 L 382.949219 1278.503906 L 456.007812 1400.277344 Z M 500.425781 1165.667969 L 1170.097656 495.988281 L 1213.132812 539.03125 L 543.460938 1208.710938 Z M 500.425781 1165.667969 " />
      <path d="M 1459.5 1692.867188 C 1527.300781 1718.359375 1603.742188 1701.828125 1654.964844 1650.609375 C 1706.191406 1599.375 1722.726562 1522.921875 1697.222656 1455.136719 Z M 1459.5 1692.867188 " />
      <path d="M 1654.96875 1389.199219 L 1313.082031 1047.175781 C 1275.371094 1078.875 1219.683594 1076.464844 1184.828125 1041.652344 C 1149.96875 1006.847656 1147.511719 951.175781 1179.15625 913.425781 L 1130.738281 864.949219 L 869.382812 1126.316406 L 918.09375 1175.023438 C 955.804688 1143.324219 1011.5 1145.726562 1046.335938 1180.527344 C 1081.191406 1215.339844 1083.664062 1271.019531 1052.015625 1308.773438 L 1393.613281 1650.550781 C 1397.339844 1654.277344 1401.273438 1657.730469 1405.246094 1661.089844 L 1665.515625 1400.820312 C 1662.164062 1396.839844 1658.695312 1392.921875 1654.96875 1389.199219 Z M 1474.492188 1513.105469 L 1170.097656 1208.710938 L 1213.132812 1165.667969 L 1517.535156 1470.078125 Z M 1474.492188 1513.105469 " />
    </g>
  </svg>
);
